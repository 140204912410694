@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('./images/bg_am.png');
  background-size: cover;
  background-position: 0px 0px;
  margin: 0;
  height: 100vh;
  width: 100vw;
}

::selection {
  color: none;
  background: none;
}

/* For Mozilla Firefox */
::-moz-selection {
  color: none;
  background: none;
}