.titre {
  font-size: clamp(14px, 2vw, 16px);
  color: rgb(255, 255, 255);
  width: 80%;
  height: 20%;
  font-weight: 300;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: default;
  margin-bottom: 0.5rem;
}

.buttonIn {
  border-radius: 5px;
  width: 80%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
}

.button-div {
  height: 80%;
  width: 15%;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: #2f208300;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  font-size: clamp(14px, 2vw, 16px);
  text-align: center;
  display: flex;
  justify-content: center;
  overflow: hidden;

}

.input-sentence {
  border: none;
  background: transparent;
  width: 100%;
  padding: 0.7rem;
  height: 80%;
  border: none;
  outline: none;
  font-size: clamp(14px, 2vw, 16px);
  color: rgb(38, 38, 38);
  border-radius: 5px;
}

.input-sentence::selection {
  color: white;
  background: #535ef6;
}

/* For Mozilla Firefox */
.input-sentence::-moz-selection {
  color: white;
  background: #535ef6;
}


html {
  --res: calc(0.01 * 10vmin);
}

.inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 13%;
  justify-content: center;
  align-items: center;
}

.img-send {
  width: 100%;
  height: 100%;
  object-fit: contain;

  border-radius: 10px;
  transition: all 0.3s;
}

.img-send:hover {
  transform: scale(1.1);
}