.footer {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3%;
    color: white;
    text-align: center;
    font-size: clamp(7px, 2vw, 9px);

    
}

html {
    --res: calc(0.01 * 10vmin);
}