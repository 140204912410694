.logos {
    height: 11%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.img {
    height: 100%;
    width: 100px;
    object-fit: cover;
}