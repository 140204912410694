.wrap {
  /*   Flex Perfect Centring  */
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
  /*   Colors */
  background-color: rgba(146, 125, 125, 0);
  box-shadow: 0px 40px 60px -20px rgba(0, 0, 0, 0.2);
}

/* SPINER */

.spinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #ff6b6b;
  border-left: 4px solid #ffffff;
  animation: spinner 2s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
