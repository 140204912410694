.App {
  position: relative;
  z-index: 0;
  display: flex;
  width: 100vw;
  max-width: 500px;
  height: 90vh;
  max-height: 800px;
  min-height: 600px;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  background-color: #535ef600;
}

.dots {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;

  z-index: -1;
  width: 40%;
  height: 40%;
  background-image: url("./images/dots.png");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: auto;
}
