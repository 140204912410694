.screen {
  height: 65%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.img {
  width: 80%;
  display: flex;
  padding: 5px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.img-output {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.btns {
  display: flex;
  height: 10%;
  width: 80%;
  padding-right: 5px;
  padding-left: 5px;
  justify-content: space-around;
  align-items: center;
}

.btn-download {
  display: flex;
  width: 48%;
  height: 90%;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #535ef6;
  cursor: pointer;
  transition: all 0.3s;

}

.btn-download:hover {
  transform: scale(1.05);
}


.btn-shared {
  display: flex;
  width: 48%;
  height: 90%;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  background-color: #ff6b6b;
  cursor: pointer;
  transition: all 0.3s;

}

.btn-shared:hover {
  transform: scale(1.05);
}

h6 {
  color: white;
  font-size: clamp(12px, 2vw, 14px);
  font-weight: 500;
}

.before-img {
  text-align: center;
  border-radius: 10px;
  color: white;
  box-sizing: border-box;

}

body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  background-color: #FEFEFE;
}

i {
  opacity: 0;
  font-size: 28px;
  color: #1F1E1E;
  will-change: transform;
  -webkit-transform: scale(.1);
  transform: scale(.1);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.btn_wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: #fd9797;
  will-change: transform;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.btn_wrap:hover {
  /* transition-delay: .4s; */
  -webkit-transform: scale(1.05);
  transform: scale(1.05)
}

span {
  position: absolute;
  display: flex;
  -webkit-box-pack: center;
  align-items: center;
  justify-content: center;
  z-index: 99;
  width: 105%;
  height: 105%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: clamp(12px, 2vw, 14px);
  text-align: center;
  color: #EEEEED;
  background-color: #ff6b6b;
  padding: 0 18px;
  -webkit-transition: all 1.2s ease;
  transition: all 1.2s ease;
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.container i:nth-of-type(1) {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.container i:nth-of-type(2) {
  -webkit-transition-delay: .9s;
  transition-delay: .9s;
}

.container i:nth-of-type(3) {
  -webkit-transition-delay: .7s;
  transition-delay: .7s;
}

.container i:nth-of-type(4) {
  -webkit-transition-delay: .4s;
  transition-delay: .4s;
}

.btn_wrap:hover span {
  -webkit-transition-delay: .25s;
  transition-delay: .25s;
  -webkit-transform: translateX(-280px);
  transform: translateX(-280px)
}

.btn_wrap:hover i {
  opacity: 1;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}


.dr {
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 100px;
}