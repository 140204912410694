.flags {
  display: flex;
  height: 6%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.fr {
  width: 10%;
  height: 80%;
  background-color: aliceblue;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  background-image: url("../../images/fr.png");
  background-size: cover;
  background-position: center;
  transition: all 0.3s;
}
.fr:hover {
  transform: scale(1.1);
}
.en {
  width: 10%;
  height: 80%;
  background-color: aliceblue;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  background-image: url("../../images/en.png");
  background-size: cover;
  background-position: center;
  transition: all 0.3s;

}
.en:hover {
  transform: scale(1.1);
}

.it {
  width: 10%;
  height: 80%;
  background-color: aliceblue;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  background-image: url("../../images/it.png");
  background-size: cover;
  background-position: center;
  transition: all 0.3s;
}
.it:hover {
  transform: scale(1.1);
}